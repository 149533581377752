
/* ================================================== */
/* Settings */
/* ================================================== */

:root {
    --main-font: 'Nunito', sans-serif;
    --main-ar-font: 'NeoSansArabicRegular', sans-serif;
    --sec-font: 'PingFangSC';
    --kids-font: 'BrushScriptMT';
    --kids-ar-font: 'Aref Ruqaa';

    --main-color: #13375C;
    --sec-color: #505F86;
    --white: #fff;
    --black: #000;
    --blue: #3C5EA4;

    --body-color: #fafafa;

    --purple: #6173a2;
    --gray: #9296A1;
    --gray-dark: #8E8E8E;

    --orange: #FF8F00;
    --orange-light: #FCAE5A;

    --red: #F1254A;
    --green: #50D2C2;
    --yellow: #d0a71c;

    --browser-context: 100;

    --kids-homework-color: #897AFF;
    --kids-exam-color: #66D6D4;
    --kids-grade-color: #FECA1F;
    --kids-evaluation-color: #FF3D4D;
    --kids-portfolio-color: #FA85C5;
    --kids-project-color: #F6E6C0;
    --kids-game-color: #C0BEEE;
    --kids-para-color: #54FBD5;

    --kids-homework-dark-color: #776ADF;
    --kids-exam-dark-color: #57BDBB;
    --kids-grade-dark-color: #EEBD17;
    --kids-evaluation-dark-color: #E73745;
    --kids-portfolio-dark-color: #E67DB6;
    --kids-project-dark-color: #ECD5A0;
    --kids-game-dark-color: #8E85D1;
    --kids-para-dark-color: #39AA90;
}