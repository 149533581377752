
/* ================================================== */
/* reset */
/* ================================================== */

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    direction: rtl;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after {
    content: '';
    content: none;
}

q:before, q:after {
    content: '';
    content: none;
}

a {
    text-decoration: none;
    color: inherit;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

button, input, textarea {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

select, textarea,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="url"],
input[type="tel"] {
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
}

input[type="submit"],
button {
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
}

input, button, a {
    outline: none;
}

a[href^="tel:"] {
    color: inherit;
    text-decoration: none;
}

body a {
    color: inherit;
    text-decoration: none;
}

body a:hover {
    color: inherit;
    text-decoration: none;
}