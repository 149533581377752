@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
*, input{
    font-family: "Cairo", serif;
}
body{
    font-family: Cairo, sans-serif;
    font-size: 13px;
}
form#formUser {
    width: 100%;
    margin: 0 auto;}
.formLoginStyle input[type=email], input[type=number], .formLoginStyle input[type=password], .formLoginStyle input[type=tel], .formLoginStyle input[type=text], select {
    font-family: Cairo, sans-serif;
    font-size: .16rem;
    width: 100%;
    height: .4rem;
    line-height: .4rem;
    color: #6173a2;
    padding: 0 .12rem;
    background: #fff;
    border: 1px solid rgba(97, 115, 162, .4);
    -moz-border-radius: .04rem;
    border-radius: .04rem;
}.formLoginStyle .submitBtn.submitBtn-large {
    width: 100%;
}

.loginContent {
    background: #ffffff; /* Assuming $white is #ffffff */

}

.loginContent .main {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
    min-height: 100vh;
}

@media screen and (max-width: 800px) {
    .loginContent .main {
        display: block;
    }
}

.loginContent .rightSide {
    position: relative;
    z-index: 1;
    flex: 1 1 58.5%;
    min-height: 100vh;
}

@media screen and (max-width: 800px) {
    .loginContent .rightSide {
        min-height: 1rem; /* Assuming rem(1) translates to 1rem */
    }
}

.loginContent .rightSide:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    bottom: 0;
    width: calc(100% + 50vw - 720px);
    background: linear-gradient(133.64deg, #5C61A3 0%, #105BA6 100%, #09203F 100%);
}

[dir=rtl] .loginContent .rightSide:before {
    right: 0;
    left: auto;
}

@media screen and (max-width: 1440px) {
    .loginContent .rightSide:before {
        width: 100%;
    }
}

.loginContent .rightSide.withPhotos:after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    bottom: 0;
    width: calc(100% + 50vw - 720px);
    background: url(https://massarservice.men.gov.ma/moutamadris/ScriptsIntegration/assets/img/photos-bg.png) right top no-repeat;
    background-size: auto 100%;
    opacity: 0.65;
}

[dir=rtl] .loginContent .rightSide.withPhotos:after {
    right: 0;
    left: auto;
    transform: scaleX(-1);
}

@media screen and (max-width: 1440px) {
    .loginContent .rightSide.withPhotos:after {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .loginContent .rightSide.withPhotos:after {
        background-position: right center;
        background-size: auto 216%;
        opacity: 0.3;
    }
}

.loginContent .rightSide .welcome {
    position: relative;
    z-index: 2;
    color: #ffffff; /* Assuming $white is #ffffff */
    padding: 84px 54px; /* Assuming rem(84) is 84px and rem(54) is 54px */
    height: 100%;
    text-shadow: 0 0 3px #5C61A3;
}

@media screen and (max-width: 1100px) {
    .loginContent .rightSide .welcome {
        padding: 60px 30px; /* Assuming rem(60) is 60px and rem(30) is 30px */
    }
}

@media screen and (max-width: 800px) {
    .loginContent .rightSide .welcome {
        padding: 22px 20px 30px; /* Assuming rem(22) is 22px and rem(20) is 20px */
        min-height: 170px; /* Assuming rem(170) is 170px */
    }
}

.loginContent .rightSide .welcome:before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url(https://massarservice.men.gov.ma/moutamadris/ScriptsIntegration/assets/img/welcome-bg.svg) center bottom 10% no-repeat;
}

[dir=rtl] .loginContent .rightSide .welcome:before {
    transform: scaleX(-1);
}

@media screen and (max-width: 1100px) {
    .loginContent .rightSide .welcome:before {
        padding: 60px 30px; /* Assuming rem(60) is 60px and rem(30) is 30px */
    }
}

@media screen and (max-height: 900px) {
    .loginContent .rightSide .welcome:before {
        background-size: auto 60%;
    }
}

@media (orientation: portrait) {
    .loginContent .rightSide .welcome:before {
        background-size: 90% auto;
    }
}

@media screen and (max-width: 800px) {
    .loginContent .rightSide .welcome:before {
        background-size: auto 116%;
        background-position: right 10% top 10px;
        opacity: 0.6;
    }
}

.loginContent .rightSide .welcome .inner {
    position: relative;
    z-index: 2;
}

.loginContent .rightSide .welcome .welcomeTitle {
    font-size: 62px; /* Assuming rem(62) is 62px */
    line-height: 62px; /* Assuming rem(62) is 62px */
    font-weight: 400;
    margin: 0 0 30px; /* Assuming rem(30) is 30px */
    max-width: 600px; /* Assuming rem(600) is 600px */
}

@media screen and (max-width: 800px) {
    .loginContent .rightSide .welcome .welcomeTitle {
        font-size: 30px; /* Assuming rem(30) is 30px */
        line-height: 35px; /* Assuming rem(35) is 35px */
        margin: 0 0 14px; /* Assuming rem(14) is 14px */
    }
}

.loginContent .rightSide .welcome .welcomeDesc {
    font-size: 18px; /* Assuming rem(18) is 18px */
    line-height: 26px; /* Assuming rem(26) is 26px */
    max-width: 530px; /* Assuming rem(530) is 530px */
}

@media screen and (max-width: 800px) {
    .loginContent .rightSide .welcome .welcomeDesc {
        font-size: 14px; /* Assuming rem(14) is 14px */
        line-height: 20px; /* Assuming rem(20) is 20px */
    }
}

.loginContent .leftSide {
    background: #ffffff; /* Assuming $white is #ffffff */
    flex: 1 1 41.5%;
    padding: 30px 0; /* Assuming rem(30) is 30px */
}

@media screen and (max-width: 800px) {
    .loginContent .leftSide {
        position: relative;
        z-index: 2;
        margin: -13px 0 0;
        padding: 18px 0; /* Assuming rem(18) is 18px */
        border-radius: 13px 13px 0 0;
    }
}

.loginContent .leftSide .inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 446px; /* Assuming rem(446) is 446px */
    padding: 0 20px; /* Assuming rem(20) is 20px */
    margin: 0 auto;
}

@media screen and (max-width: 800px) {
    .loginContent .leftSide .inner {
        max-width: 640px; /* Assuming rem(640) is 640px */
    }
}

.loginContent .ministryLogo {
    margin: 0 0 30px; /* Assuming rem(30) is 30px */
}

.loginContent .ministryLogo img {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 347px; /* Assuming rem(347) is 347px */
    width: 100%;
}

@media screen and (max-width: 800px) {
    .loginContent .ministryLogo img {
        width: 78%;
    }
}

.loginContent .formLogin {}

@media screen and (max-width: 800px) {
    .loginContent .formLogin {
        margin: 0 0 16px; /* Assuming rem(16) is 16px */
    }
}

.loginContent .formLogin .massarLogo {
    margin: 0 0 30px; /* Assuming rem(30) is 30px */
}

@media screen and (max-width: 800px) {
    .loginContent .formLogin .massarLogo {
        margin: 0 0 16px; /* Assuming rem(16) is 16px */
    }
}

.loginContent .formLogin .massarLogo img {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 245px; /* Assuming rem(245) is 245px */
    width: 90%;
}

@media screen and (max-width: 800px) {
    .loginContent .formLogin .massarLogo img {
        width: 42%;
    }
}

.loginContent .formLogin a {
    font-size: 12px; /* Assuming rem(12) is 12px */
    line-height: 16px; /* Assuming rem(16) is 16px */
    color: #ABB5CE; /* Assuming $gray is #ABB5CE */
}

.loginContent .formLogin a:hover {
    text-decoration: underline;
}

.loginContent .navLang ul {
    font-size: 0;
}

.loginContent .navLang ul li {
    font-size: 14px; /* Assuming rem(14) is 14px */
    line-height: 18px; /* Assuming rem(18) is 18px */
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
}

.loginContent .navLang ul li::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 6px; /* Assuming rem(6) is 6px */
    width: 1px;
    background: #ABB5CE;
    margin: 0 8px; /* Assuming rem(8) is 8px */
}

[dir=rtl] .loginContent .navLang ul li::before {
    margin: 4px 8px 0;
}

.loginContent .navLang ul li:first-child::before {
    display: none;
}

.loginContent .navLang ul li a {
    display: inline-block;
    vertical-align: middle;
    color: #ABB5CE;
}

.loginContent .navLang ul li a.current {
    font-weight: 700;
    color: #105BA6; /* Assuming $secColor is #105BA6 */
}

.loginContent .navLang ul li a:not(.current):hover {
    color: #105BA6; /* Assuming $secColor is #105BA6 */
}