/* ADashboard.css */
.wrapperNav .inner {
    background: linear-gradient(257deg, #8e44ad, #9b59b6, #2980b9);
}

.button {
    background-color: #ff6b6b; /* لون الزر */
    color: white; /* لون النص في الزر */
}

/* إعدادات عامة للوحة */
.dashboard {
    padding: 20px;
    background-color: #f8f9fa; /* لون خلفية فاتح */
    border-radius: 8px; /* زوايا دائرية */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* ظل خفيف */
}

/* إعدادات الصف */
.info-row {
    display: flex; /* استخدام flexbox لترتيب العناصر بجانب بعض */
    justify-content: space-between; /* توزيع العناصر بشكل متساوي */
    margin-top: 20px; /* إضافة مسافة فوق الصف */
}


/* تأثير التحويم */
.info-box:hover {
    transform: translateY(-5px); /* تحريك الصندوق لأعلى عند التحويم */
}

/* إعدادات الألوان لصناديق المعلومات */
.red {
    background-color: #dc3545; /* اللون الأحمر */
}

.blue {
    background-color: #007bff; /* اللون الأزرق */
}

.green {
    background-color: #28a745; /* اللون الأخضر */
}

/* إعدادات النصوص */
.text {
    font-size: 14px; /* حجم الخط للنص */
    margin-bottom: 5px; /* مسافة أسفل النص */
}

.number {
    font-size: 24px; /* حجم الخط للعدد */
    font-weight: bold; /* جعل الرقم بارز */
}








/* إعدادات صناديق المعلومات */
.info-box {
    flex: 1 1;
    margin: 0 10px;
    padding: 20px;
    border-radius: 8px;
    color: white;
    display: flex;
    transition: transform 0.3s;
    height: 50px;
}

.chart-container {
    margin-top: 40px; /* إضافة مساحة فوق الرسم البياني */
}

.chart-container h2 {
    font-size: 24px; /* تكبير خط العنوان */
    margin-bottom: 20px; /* إضافة مساحة تحت العنوان */
}
