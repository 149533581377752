
/* ================================================== */
/* Form */
/* ================================================== */
.formLoginStyle label {
    position: relative;
    font-size: 12px; /* rem(12) */
    line-height: 16px; /* rem(16) */
    color: gray; /* $gray */
    cursor: pointer;
}

.formLoginStyle label input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}

.formLoginStyle label input[type="checkbox"] + span {
    position: relative;
    display: inline-block;
    padding: 1px 0 0 24px; /* rem(1) 0 0 rem(24) */
}

.formLoginStyle label input[type="checkbox"] + span[dir="rtl"] {
    padding: 1px 24px 0 0; /* rem(1) rem(24) 0 0 */
}

.formLoginStyle label input[type="checkbox"] + span::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px; /* rem(16) */
    height: 16px; /* rem(16) */
    border: 1px solid gray; /* $gray */
    border-radius: 2px; /* rem(2) */
}

.formLoginStyle label input[type="checkbox"] + span[dir="rtl"]::before {
    left: auto;
    right: 0;
}

.formLoginStyle label input[type="checkbox"] + span::after {
    content: '';
    position: absolute;
    top: 4px; /* rem(4) */
    left: 4px; /* rem(4) */
    width: 10px; /* rem(10) */
    height: 10px; /* rem(10) */
    background: gray; /* $gray */
    opacity: 0;
    /* animation class removed for simplicity */
}

.formLoginStyle label input[type="checkbox"]:checked + span::after {
    opacity: 1;
}

.formLoginStyle .item {
    position: relative;
    margin: 0 0 30px; /* 0 0 rem(30) */

}

@media screen and (max-width: 800px) {
    .formLoginStyle .item {
        text-align: center;
        margin: 0 0 16px; /* 0 0 rem(16) */
    }
}

.formLoginStyle input[type="text"],
.formLoginStyle input[type="email"],
.formLoginStyle input[type="password"],
.formLoginStyle input[type="tel"] {
    font-family:  "Cairo", serif;; /* $mainFont */
    font-size: 16px; /* rem(16) */
    width: 100%;
    height: 40px; /* rem(40) */
    line-height: 40px; /* rem(40) */
    color: purple; /* $purple */
    padding: 0 12px; /* 0 rem(12) */
    background: white; /* $white */
    border: 1px solid rgba(purple, 0.4); /* rgba($purple, 0.4) */
    border-radius: 4px; /* rem(4) */
    /* animation class removed for simplicity */
}

.formLoginStyle input[type="text"][dir="rtl"],
.formLoginStyle input[type="email"][dir="rtl"],
.formLoginStyle input[type="password"][dir="rtl"],
.formLoginStyle input[type="tel"][dir="rtl"] {
    font-family: 'YourArabicFontFamily'; /* $mainArFont */
}

.formLoginStyle input[type="text"]:focus,
.formLoginStyle input[type="email"]:focus,
.formLoginStyle input[type="password"]:focus,
.formLoginStyle input[type="tel"]:focus {
    box-shadow: 0 0 1px 1px rgba(97, 115, 162, 0.2);
}

.formLoginStyle input[type="text"].error,
.formLoginStyle input[type="email"].error,
.formLoginStyle input[type="password"].error,
.formLoginStyle input[type="tel"].error {
    box-shadow: 0 0 1px 1px rgba(red, 0.8);
}

.formLoginStyle .submitBtn {
    font-family: "Cairo", serif; /* $mainFont */
    font-size: 16px; /* rem(16) */
    height: 40px; /* rem(40) */
    line-height: 40px; /* rem(40) */
    color: white; /* $white */
    background: #0779fe;
    background-size: 200% 200%;
    background-position: left top;
    border-radius: 4px; /* rem(4) */
    padding: 0 20px; /* 0 rem(20) */
    border: none;
    cursor: pointer;
    width: 100%;
    transition: background 6.5s ease; /* تعديل هنا */
}

.formLoginStyle .submitBtn:hover {
    background: linear-gradient(70deg, 
    #0779fe 0%, 
    #032751 35%, 
    #032752 76%, 
    #000000 97%);
    transition: background 6.5s ease; /* تعديل هنا */
}


.formLoginStyle .submitBtn[dir="rtl"] {
    font-family: 'Cairo'; /* $mainArFont */
}

.formLoginStyle .submitBtn.submitBtn-large {
    width: 100%;
}

.formLoginStyle .submitBtn:hover {
    background-position: right bottom;
}

.formLoginStyle .errorLine {
    position: absolute;
    z-index: 3;
    color: white; /* $white */
    font-size: 12px; /* rem(12) */
    line-height: 14px; /* rem(14) */
    top: 0;
    left: 0;
    padding: 4px 6px; /* rem(4) rem(6) */
    margin: -8px 0 0; /* rem(-8) 0 0 */
    background: rgba(red, 0.8); /* rgba($red, 0.8) */
    border-radius: 2px;
    transform: translateY(-100%);
}

.formLoginStyle .errorLine[dir="rtl"] {
    right: 0;
    left: auto;
}

.formLoginStyle .errorLine::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 8px; /* rem(8) */
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid rgba(red, 0.8); /* rgba($red, 0.8) */
    transform: translateY(100%);
}

.formLoginStyle .errorLine[dir="rtl"]::after {
    right: 8px; /* rem(8) */
    left: auto;
}

.formLoginStyle .errorZone {
    font-size: 13px; /* rem(13) */
    color: white; /* $white */
    line-height: 16px; /* rem(16) */
    padding: 12px; /* rem(12) */
    margin: 0 0 30px; /* 0 0 rem(30) */

}

@media screen and (max-width: 800px) {
    .formLoginStyle .errorZone {
        text-align: center;
        margin: 0 0 16px; /* 0 0 rem(16) */
    }
}

.formLoginStyle .errorZone.MsgErr {
    background: rgba(red, 0.8); /* rgba($red, 0.8) */
}

.formLoginStyle .errorZone.MsgWar {
    background: rgba(yellow, 0.8); /* rgba($yellow, 0.8) */
}

.formLoginStyle .errorZone.MsgValid {
    background: rgba(green, 0.8); /* rgba($green, 0.8) */
}

.formStyle label {
    font-size: 16px; /* rem(16) */
    line-height: 20px; /* rem(20) */
    color: secondaryColor; /* $secColor */
    font-weight: 400;
    margin: 0 0 6px; /* 0 0 rem(6) */
    display: inline-block;
}

.formStyle label input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}

.formStyle label input[type="checkbox"] ~ span {
    position: relative;
    display: inline-block;
    font-size: 14px; /* rem(14) */
    line-height: 16px; /* rem(16) */
    padding: 0 10px 0 20px; /* 0 rem(10) 0 rem(20) */
    min-height: 14px; /* rem(14) */
    cursor: pointer;
}

.formStyle label input[type="checkbox"] ~ span[dir="rtl"] {
    padding: 0 20px 0 10px; /* 0 rem(20) 0 rem(10) */
}

.formStyle label input[type="checkbox"] ~ span::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 12px; /* rem(12) */
    height: 12px; /* rem(12) */
    border: 1px solid secondaryColor; /* $secColor */
    border-radius: 2px; /* rem(2) */
    /* animation class removed for simplicity */
}

.formStyle label input[type="checkbox"] ~ span[dir="rtl"]::before {
    left: auto;
    right: 0;
}

.formStyle label input[type="checkbox"]:checked ~ span::after {
    content: '';
    position: absolute;
    left: 3px; /* rem(3) */
    top: 3px; /* rem(3) */
    width: 6px; /* rem(6) */
    height: 6px; /* rem(6) */
    background: secondaryColor; /* $secColor */
    border-radius: 2px; /* rem(2) */
}

.formStyle label input[type="checkbox"]:checked ~ span[dir="rtl"]::after {
    left: auto;
    right: 3px; /* rem(3) */
}

.formStyle input[type="text"],
.formStyle input[type="email"],
.formStyle input[type="password"],
.formStyle input[type="tel"] {
    font-family:  "Cairo", serif;; /* $mainFont */
    font-size: 16px; /* rem(16) */
    width: 100%;
    height: 40px; /* rem(40) */
    line-height: 40px; /* rem(40) */
    color: purple; /* $purple */
    padding: 0 12px; /* 0 rem(12) */
    background: white; /* $white */
    border: 1px solid rgba(purple, 0.4); /* rgba($purple, 0.4) */
    border-radius: 4px; /* rem(4) */
    /* animation class removed for simplicity */
}

.formStyle input[type="text"][dir="rtl"],
.formStyle input[type="email"][dir="rtl"],
.formStyle input[type="password"][dir="rtl"],
.formStyle input[type="tel"][dir="rtl"] {
    font-family: 'YourArabicFontFamily'; /* $mainArFont */
}

.formStyle input[type="text"]:focus,
.formStyle input[type="email"]:focus,
.formStyle input[type="password"]:focus,
.formStyle input[type="tel"]:focus {
    box-shadow: 0 0 1px 1px rgba(97, 115, 162, 0.2);
}

.formStyle input[type="text"].error,
.formStyle input[type="email"].error,
.formStyle input[type="password"].error,
.formStyle input[type="tel"].error {
    box-shadow: 0 0 1px 1px rgba(red, 0.8);
}



