
html {
    font-size: 100px;
    overflow-x: hidden;
}

html.noScroll,
body {
    overflow: hidden;
}

@media screen and (max-width: 1300px) and (min-width: 801px) {
    html {
        font-size: calc(((100vw - 800px) / 28) + 82px);
    }
}

@media screen and (max-width: 800px) and (min-width: 601px) {
    html {
        font-size: 100px;
    }
}

@media screen and (max-width: 360px) and (min-width: 331px) {
    html {
        font-size: calc(((100vw - 330px) / 3) + 90px);
    }
}

@media screen and (max-width: 330px) {
    html {
        font-size: 90px;
    }
}

body {
    font-family: 'Cairo', sans-serif; /* Replace $mainFont with the actual font */
    font-weight: 400;
    font-size: 16px; /* Assuming rem(16) is 16px */
    background: #f5f5f5; /* Replace $bodyColor with the actual color */
    color: #333; /* Replace $mainColor with the actual color */
    overflow-x: hidden;
}

[dir=rtl] body {
    font-family: 'Cairo', sans-serif; /* Replace $mainArFont with the actual font */
}

.global {
    /* Add any styles for the .global class if needed */
}

.switcherContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;
    padding: 88px 0 24px; /* Assuming rem(88) is 88px and rem(24) is 24px */
}

@media screen and (max-width: 800px) {
    .switcherContent {
        padding: 66px 0 16px; /* Assuming rem(66) is 66px and rem(16) is 16px */
    }
}

.choiceContainer {
    flex-grow: 1;
}

.choiceContainer .choiceInner {
    max-width: 1260px;
    margin: 0 auto;
    padding: 50px 30px 0; /* Assuming rem(50) is 50px and rem(30) is 30px */
}

@media screen and (max-width: 800px) {
    .choiceContainer .choiceInner {
        padding: 38px 16px 0; /* Assuming rem(38) is 38px and rem(16) is 16px */
    }
}

.dashboardContent {
    display: flex;
    overflow: hidden;
}

.dashboardContent.kidsNav {
    background: #ffffff; /* Assuming $white is #ffffff */
}

@media screen and (max-width: 800px) {
    .dashboardContent {
        display: block;
    }
}

.mainContent {
    flex-grow: 1;
    padding: 26px 32px; /* Assuming rem(26) is 26px and rem(32) is 32px */
    overflow: hidden;
}

@media screen and (max-width: 800px) {
    .mainContent {
        padding: 20px 16px; /* Assuming rem(20) is 20px and rem(16) is 16px */
    }
}

.openCollabSpace .mainContent,
.openMenu .mainContent {
    @media screen and (min-width: 801px) {
        filter: blur(1px);
    }
}
